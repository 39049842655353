<template>
    <div class="module-preview-wrapper">
        <div class="module-preview--header">image gallery: full-width image and thumbnails</div>
        <div class="module-preview--contents relative" v-if="imageIds">
            <div class="main-image">
                <img :src="(imageIds[0])" alt="">
            </div>
            <div class="images">
                <div class="image-wrapper" v-for="imageId in imageIds.slice(1, 5)" :key="imageId">
                    <img :src="(imageId)">
                </div>                
                <span class="count bg-blue-100 border border-blue-300 py-3 px-5 text-blue-900 font-500 text-sm" v-if="(imageIds.length - 5) > 0">
                    + {{ imageIds.length - 5 }} images
                </span>
            </div>            
        </div>
    </div>     
</template>

<script>
import {mapGetters} from "vuex";
    import Widget from "@/mixins/Widget";

    export default {
        mixins: [ Widget ],
        data: () => {
            return {
                imageIds: []
            }
        },
        computed: {
            ...mapGetters({
                getThumbnailUrlByFileId: 'files/getThumbnailUrlByFileId'
            })
        },
        created() {
            this.imageIds = JSON.parse(this.getValue('images'));
        }
    }
</script>

<style scoped>
    .module-preview--contents{
        grid-template-columns: 1fr 1fr;        
    }
    .main-image{
        max-height: 350px;
        width: 100%;        
        overflow:hidden;
    }
    .main-image img{
        object-fit: cover;
        object-position: 50% 50%;
        width: 100%;
        height: 100%;
    }
    .module-preview--contents .images{
        display:grid;
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
        grid-template-rows: max-content;
        grid-gap: 15px;
    }
    .module-preview--contents .image-wrapper{        
        height: 115px;
        width: 100%;
        overflow: hidden;
    } 
    .module-preview--contents .image-wrapper img{
        object-fit: cover;
        object-position: 50% 50%;
        width: 100%;
        height: 100%;
    }
    .count {
        height: fit-content;
        text-align: center;
        /* position: absolute;
        right: calc(1.5rem + 10px);
        bottom: 0px; */
    }
</style>